export default class RolesEntity {
    static getEntityData(elem: any) {
        return {
            uuid: elem.querySelector("#role_uuid") ? (elem.querySelector("#role_uuid") as HTMLInputElement).value : null,
            name: (elem.querySelector("#role_name") as HTMLInputElement).value,
            ticket_assigned:  (elem.querySelector("#role_ticket_assigned") as HTMLInputElement).checked,
            // @ts-ignore
            members: elem.querySelector("#role_members") ? Array.from(elem.querySelectorAll("#role_members option:checked")).map(o => o.value) : [],
            // @ts-ignore
            customers: elem.querySelector("#role_customers") ? Array.from(elem.querySelectorAll("#role_customers option:checked")).map(o => o.value) : [],
            // @ts-ignore
            permissions: elem.querySelector(".role_permission_model_row") ? Array.from(elem.querySelectorAll(".role_permission_model_row")).map(a => { return { model: a.querySelector("input[type='hidden']").value, can_read: a.querySelector("input[type='checkbox'][data-type='can_read']").checked, can_create: a.querySelector("input[type='checkbox'][data-type='can_create']").checked, can_update: a.querySelector("input[type='checkbox'][data-type='can_update']").checked, can_read_navbar: a.querySelector("input[type='checkbox'][data-type='can_read_navbar']").checked, can_destroy: a.querySelector("input[type='checkbox'][data-type='can_destroy']").checked } }) : []
        }
    }
}